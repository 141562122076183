import React from 'react';
import './App.css';
import {Route, BrowserRouter} from 'react-router-dom';

import Authenticate from './pages/Authenticate';
import Welcome from './pages/Welcome';
import Rating from './pages/Rating';
import Thanks from './pages/Thanks';

function App() {
  const isAuthenticated = sessionStorage.getItem('device');
  return (
    <BrowserRouter>
      {isAuthenticated?
      <>
        <Route exact path="/welcome" component={Welcome} />
        <Route exact path="/device/:device" component={Rating} />
        <Route exact path="/thanks" component={Thanks} />
      </>:
      <Route exact path="/" component={Authenticate}/>
      }
    </BrowserRouter>
  );
}

export default App;