import React, { useEffect, useState } from "react";
import _ from 'lodash';
import * as Realm from 'realm-web';
import { Box, Rating, Avatar } from '@mui/material';
import { SentimentDissatisfied, SentimentSatisfied, SentimentSatisfiedAlt, SentimentVerySatisfied, OpenInFull } from '@mui/icons-material';

import { useHistory } from "react-router-dom";

import ratingSound from "../audios/rating.mp3"
import image001 from '../images/image001.png'

const realmapp = new Realm.App({id: "khaosatnguoihoc-rndsu"});
const credentials = Realm.Credentials.anonymous();

const customIcons = {
    1: {
        icon: <SentimentVerySatisfied sx={{ fontSize: 80 }}/>,
        label: 'Tốt',
    },
    2: {
      
        icon: <SentimentSatisfiedAlt sx={{ fontSize: 80 }}/>,
        label: 'Khá',
    },
    3: {
        icon: <SentimentSatisfied sx={{ fontSize: 80 }}/>,
        label: 'Bình thường',
    },
    4: {
        icon: <SentimentDissatisfied sx={{ fontSize: 80 }}/>,
        label: 'Kém',
    },
};

function IconContainer(props){
    const { value, ...other } = props;
    return(
        <>
            <div className="d-flex align-items-center flex-column justify-content-center" style={{width: "20vw"}}>
                <p {...other}>{customIcons[value].icon}</p>
                <p {...other}>{customIcons[value].label}</p>
            </div>
        </>
    );
}

function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
}
  
function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            width: 90, height: 90
        },
        children: <span className="fs-2 fw-bold">{name.split(' ')[0][0]}{name.split(' ')[1][0]}</span>,
    };
}

function Rate(props){
    var history = useHistory();
    const staff = sessionStorage.getItem("user");
    const [data, setData] = useState([]);

    async function dataName(p){
        const realmUser = await realmapp.logIn(credentials);
        setData(await realmUser.callFunction('getStaff', {email: p}));
    }
    useEffect(()=>{
        dataName(staff);
    },[staff]);

    async function handleChange(e){
        var tmp = e.target.value;
        var r;
        // eslint-disable-next-line default-case
        switch(+tmp){
            case 1: r = 'Tốt'; break;
            case 2: r = 'Khá'; break;
            case 3: r = 'Bình thường'; break;
            case 4: r = 'Kém'; break;
        }
        const realmUser = await realmapp.logIn(credentials);
        const tmp2 = await realmUser.callFunction('insertRating', {displayName: data[0].displayName, email: staff, rating: r});
        sessionStorage.setItem("ratingAction", "done "+ tmp2.id);
        history.push("/thanks");
    }

    return(
        <>
        <audio id="audio" src={ratingSound} autoPlay></audio>
        {_.isEmpty(data)?<></>:
        <div style={{backgroundColor: "#f1f1f1"}}>
            <div className="d-flex align-items-center justify-content-center flex-column vh-100 position-relative">
                <img className="m-3 position-absolute top-0 start-0" src="https://vhu.edu.vn/Resources/Images/SubDomain/HomePage/Logo/1Logo_VHU.png" alt="" height="50" />
                <button className="btn position-absolute top-0 end-0 m-3" onClick={()=>document.documentElement.requestFullscreen()}><OpenInFull /></button>
                <div className="d-flex align-items-center justify-content-center flex-column mt-4">
                    <h1 className="fw-bold fs-1" style={{color: "#0098FA"}}>Bạn cảm thấy thế nào về dịch vụ?</h1><br/>
                    <Rating name="rating" IconContainerComponent={IconContainer} highlightSelectedOnly onChange={handleChange} max={4}/>
                </div>
                <img className="position-absolute bottom-0 vw-100" src={image001} height="20px"/>
            </div>
        </div>}
        </>
    );
}
export default Rate;