import React from "react";
import { useHistory } from "react-router-dom";
import image001 from '../images/image001.png'

import thanksSound from "../audios/thanks.mp3"

function Thanks(props){
    var history = useHistory();
    setTimeout(()=>history.push("/welcome"), 10000);
    return(
        <>
        <audio id="audio" src={thanksSound} autoPlay></audio>
        <div style={{backgroundColor: "#f1f1f1"}}>
            <div className="d-flex align-items-center justify-content-center flex-column vh-100 position-relative">
                <img className="m-3 position-absolute top-0 start-0" src="https://vhu.edu.vn/Resources/Images/SubDomain/HomePage/Logo/1Logo_VHU.png" alt="" height="50" />
                <h1 style={{color: "#0098FA", fontWeight:"bolder", fontSize:"60px", marginBottom: "0px"}}>Cảm ơn bạn đã đánh giá!</h1>
                <h1 style={{color: "#0098FA", fontWeight:"bolder", fontSize:"60px", marginTop: "0px"}}>Hẹn gặp lại!</h1>
            </div>
            <img className="position-absolute bottom-0 vw-100" src={image001} height="20px"/>
        </div>
        </>
    );
}
export default Thanks;