import React from 'react';
import _ from 'lodash';
import * as bootstrap from 'bootstrap';
import * as Realm from 'realm-web';
import image001 from '../images/image001.png'

const realmapp = new Realm.App({id: "khaosatnguoihoc-rndsu"});
const credentials = Realm.Credentials.anonymous();

function Authenticate(props){
    async function handleChange(e){
        var pin = e.target.value;
        if(pin.length === 6){
            const realmUser = await realmapp.logIn(credentials);
            const rs = await realmUser.callFunction('getPIN', {code: pin});
            if(_.isEmpty(rs)){
                var trigger = document.getElementById('falseLoginToast');
                var toast = new bootstrap.Toast(trigger);
                e.target.value = "";
                toast.show();
                e.target.blur();
            }else{
                sessionStorage.setItem(`device`,rs[0].device);
                sessionStorage.setItem(`user`, rs[0].email);
                window.location.href = `/welcome`;
            }
        }
    }

    // useEffect(()=>{
    //     if(pin.length === 6){
    //         console.log(pin,data);
    //         dataName(pin);
    //         // if(!_.isEmpty(data))
    //         // console.log(data);
    //         // sessionStorage.setItem(data.device, 'authenticated')
    //     }
    // });

    return(
    <>
    <div style={{backgroundColor: "#f1f1f1"}}>
        <div className="d-flex align-items-center justify-content-center flex-column vh-100 position-relative">
            <img className="m-3 position-absolute top-0 start-0" src="https://vhu.edu.vn/Resources/Images/SubDomain/HomePage/Logo/1Logo_VHU.png" alt="" height="50" />
            <h1 className="fw-bold fs-1 m-3" style={{color: "#0098FA"}}>Vui lòng nhập mã PIN</h1><br/>
            <div className="form-floating mb-3">
                <input type="number" className="form-control form-control-lg" id="floatingInput" placeholder="PIN" onChange={handleChange} required/>
                <label for="floatingInput">PIN</label>
            </div>
            <img className="position-absolute bottom-0 vw-100" src={image001} height="20px"/>
        </div>
        <div className="toast-container position-fixed top-0 end-0 p-3" id="notifications">
            <div id="falseLoginToast" className="toast" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                    <img src="https://vhu.edu.vn/Resources/Images/SubDomain/HomePage/Logo/logo-footer.png" className="rounded me-2" alt="..." style={{"height":"25px"}} />
                    <strong className="me-auto" style={{color: "#0098FA"}}>Lỗi</strong>
                    <small>Vài giây trước</small>
                    <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div className="toast-body">
                    Mã PIN không tồn tại, vui lòng nhập lại!
                </div>
            </div>
        </div>
    </div>
    </>
    );
}
export default Authenticate;