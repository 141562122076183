import React, { useEffect, useState } from "react";
import _ from 'lodash';
import * as Realm from 'realm-web';
import { Box, Avatar } from '@mui/material';
import { OpenInFull } from '@mui/icons-material';
import image001 from '../images/image001.png'

import { useHistory } from "react-router-dom";

const realmapp = new Realm.App({id: "khaosatnguoihoc-rndsu"});
const credentials = Realm.Credentials.anonymous();

function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
}
  
function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            width: 90, height: 90
        },
        children: <span className="fs-2 fw-bold">{name.split(' ')[0][0]}{name.split(' ')[1][0]}</span>,
    };
}

function Welcome(props){
    var history = useHistory();
    const staff = sessionStorage.getItem("user");
    const device = sessionStorage.getItem("device");
    const [data, setData] = useState([]);

    async function dataName(p){
        const realmUser = await realmapp.logIn(credentials);
        setData(await realmUser.callFunction('getStaff', {email: p}));
    }
    useEffect(()=>{
        dataName(staff);
    },[staff]);
    
    function handleClick(e){
        history.push(`/device/${device}`);
    }

    return(
        <>
        {_.isEmpty(data)?<></>:
        <div style={{backgroundColor: "#f1f1f1"}}>
            <div className="d-flex align-items-center justify-content-center flex-column vh-100 position-relative">
                <img className="m-3 position-absolute top-0 start-0" src="https://vhu.edu.vn/Resources/Images/SubDomain/HomePage/Logo/1Logo_VHU.png" alt="" height="50" />
                <button className="btn position-absolute top-0 end-0 m-3" onClick={()=>document.documentElement.requestFullscreen()}><OpenInFull /></button>
                <div className="d-flex align-items-center m-4">
                    {_.isEmpty(data[0].image)?
                    <Avatar {...stringAvatar(data[0].displayName)} />:
                    <Avatar src={data[0].image} />}
                    <h1 className="fs-2 fw-bold ms-3">{data[0].displayName}</h1>
                </div>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <h1 className="fw-bold fs-1" style={{color: "#0098FA"}}>XIN CHÀO BẠN!</h1><br/>
                    <button className="btn btn-outline-primary btn-lg" onClick={handleClick}>Bắt đầu đánh giá</button>
                </Box>
                <img className="position-absolute bottom-0 vw-100" src={image001} height="20px"/>
            </div>
        </div>}
        </>
    );
}
export default Welcome;